.shopTitle {
  margin-top: 4%;
  margin-bottom: 5%;
  text-align: center;
  font-size: 100%;
}

.products {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr; /* Adjust the minmax value as per your desired grid item width */
  place-items: center;
  grid-row-gap: 40px;
  margin-bottom: 100px;
  justify-items: center; /* Center the grid items horizontally */
}

#productContainer{
  height: 300px;
  width: 200px;
}

.product {
  width: 200px;
  min-width: 200px;
  height: 200px;
  min-height: 200px; /* set a minimum height for the product container */
  display: flex;
  flex-direction: column;
  border:1px solid #BEBFB2;
  background-color: #F8F9F1;
  margin-bottom: 10px;
}

.product.grow {
  transition: all .2s ease-in-out;
}

.product.grow:hover {
  transform: scale(1.011);
}

.product img {
  height: 200px;
  /* set a fixed height for the image element */
  width: 200px;
}

.product .description {
  font-size: 100%;
  text-align: center;
}

.product:hover {
  transition: 0.3s ease-in;
  cursor: pointer;
}

#product-original-price-label{
  text-decoration: line-through;
}

#product-actual-price-label{
  margin-left: 10px;
}

#product-details-specs-div{
  margin-top: -20px;
}

#product-details-specs-button{
  margin-left: 10px;
}

#price-history-div{
  margin-left: 15px;
  cursor: pointer;
}

#spec-code-temp{
  margin-top: 5px;
}

#spec-code-temp-label{
  width: 70px;
}

#current-line{
  margin-left: -10px;
  margin-top: -7px;
}

#accordion-arrow{
  margin-top: 5px;
  margin-right: 25px;
}

#add-to-cart-div{
  margin-top: 20px;
}

#add-to-cart-div-row{
  margin-left: 2px;
  margin-top: 40px;
}

.addToCartBttn {
  background-color: black;
  color: white;
  min-width: 100px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 20px;
  margin-top: -10px;
}

.addToCartBttn:hover {
  background-color: rgb(19, 19, 19);
  color: white;
  cursor: pointer;
}

#fa-button-style{
  margin-left: 30px;
}

#frequently-bought-div{
  width: 600px;
  margin-bottom: 20px;
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
}

#price-progression-div{
  margin-top: 25px;
}

#share-div{
  margin-bottom: 40px;
}

.hide {
  display: none;
}

#space-div{
  height: 100px;
}

#updateQuantityButton{
 background-color: transparent;
 border: 0px; 
 font-weight: bolder;
 border-bottom: 1px solid black;
}

.thumbnail-scroll-container {
  display: flex;
  overflow-x: scroll;
  flex-wrap: nowrap;
  width: 100%;
  margin-left: 5px;
}

.thumbnail-col {
  width: 110px;
  margin-right: -45px;
}

.img-thumbnail {
  width: 100px;
  height: 80px;
  object-fit: contain;
  cursor: pointer;
  margin-left: 5px !important;
}

.img-thumbnail.active {
  border: 2px solid red;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.tab-container {
  display: flex;
  flex-direction: column;
}

.tab-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: -25px;
  margin-bottom: -25px;
  height: '20px';
}

.tab {
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  color: #888;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.tab.active {
  color: #333;
  border-bottom: 3px solid #333;
}

.tab:hover {
  color: #333;
}

.tab-content {
  flex: 1;
}

.tab-pane {
  display: none;
}

.tab-pane.active {
  display: block;
}

.tab-pane p {
  font-size: 16px;
  line-height: 1.5;
  color: #666;
}

.overview-photo {
  display: grid;
  margin-left: 7%;
  margin-top: 4%;
}

.specs {
  margin-left: 23px;
  margin-top: -13px;
}

.carousel {
  width: 90%;
}

.productRelated {
  border-radius: 15px;
  width: 200px;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 25px;

  border-style: solid;
  border-color: #BEBFB2;

  background-color: #F8F9F1;
  margin-right: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
  flex-shrink: 0;
}

.productRelated.grow {
  transition: all .2s ease-in-out;
}

.productRelated.grow:hover {
  transform: scale(1.011);
}

.productRelated img {
  width: 180px;
}

.productRelated .description {
  text-align: center;
}

.productRelated:hover {
  transition: 0.3s ease-in;
  cursor: pointer;
}

#product-details-nom{
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 1.5em;
}

#product-details-summary{
  margin-top: 20px;
}

#product-details-price-div{
  margin-top: 5px;
  margin-left: 2px;
}

.countHandlerInProductDetails input {
  width: 40px;
  text-align: center;
  font-weight: bolder;
  border: 0px;
  border-bottom: 1px solid black;
}

.colorChoice {
  cursor: pointer;
}

.colorChoice:hover {
  transform: scale(1.031);
}


.blog {
  position: relative;
  width: 100%;
  height: 450px;
  overflow: hidden;
  margin-bottom: 80px;
}

.blogSlide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.blogSlide.active {
  opacity: 1;
}

.blogSlide-content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
}



.home-element {
  width: 90%;
  min-height: 200px;
  /* set a minimum height for the product container */
  border: 1px solid #BEBFB2;
}

.home-element.grow {
  transition: all .2s ease-in-out;
}

.home-element.grow:hover {
  transform: scale(1.011);
  cursor: pointer;
}

.home-element-content {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 10;
  width: 65%;
  background-color: rgb(26, 31, 2);
  color: white;
  padding: 5px;
  opacity: 83%;
  cursor: pointer;
}

#home-element-div{
  position: relative;
  width: 100%;
  min-height: 200px;
}

#home-category-img{
  width: 100%;
  min-height: 150px;
  object-fit: cover;
}

#home-element-img{
  width: 100%;
  min-height: 200px;
}

#carousel-img-slide{
  max-width: 890;
  max-height: 600;
  margin-left: 40px;
}

#carousel-img-item{
  max-width: 890;
  max-height: 600;
}

#containerHeader {
  height: 720px;
}

#containerHeaderLeft {
  height: 720px;
}

.column-scrollable {
  height: 720px;
  overflow-y: scroll;
}

/* Media query for smartphones */
@media (max-width: 767px) {
  .products {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .product img {
    height: 170px;
    width: 170px;
  }

  #productContainer{
    height: 300px;
    width: 170px;
  }

  .product {
    width: 170px;
    min-width: 170px;
    height: 170px;
    min-height: 170px; /* set a minimum height for the product container */
    display: flex;
    flex-direction: column;
    border:1px solid #BEBFB2;
    background-color: #F8F9F1;
  }

    /* No fixed height */
    .column-scrollable {
      overflow-y: visible;
    }
  
    .tab-container {
      margin-top: 295px;
    }
  
    #description {
      margin-top: 20px;
    }
  
    .tab {
      padding: 5px 10px;
      font-size: 14px;
    }
  
    .home-element-content {
      position: absolute;
      top: 80%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      z-index: 10;
      width: 65%;
      background-color: rgb(26, 31, 2);
      color: white;
      padding: 5px;
      opacity: 83%;
      cursor: pointer;
      font-size: 15px;
    }

    #sous-titre{
      font-size: 18px;
    }
}