.blogs {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    grid-row-gap: 40px;
    /* add a gap of 20px between the rows */
    margin-bottom: 60px;
  }

.blog-element {
    width: 90%;
    min-height: 300px;
    /* set a minimum height for the product container */
    border: 1px solid #BEBFB2;
  }
  
  .blog-element.grow {
    transition: all .2s ease-in-out;
  }
  
  .blog-element.grow:hover {
    transform: scale(1.011);
    cursor: pointer;
  }
  
  .blog-element-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 10;
    width: 65%;
    background-color: rgb(26, 31, 2);
    color: white;
    padding: 5px;
    border: 1px solid #d3a015;
  }