.order-products-table {
    width: 98%;
    border-collapse: collapse;
  }
  
  .order-products-table th,
  .order-products-table td {
    padding: 10px;
    text-align: left;
  }
  
  .order-products-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .order-products-table td {
    border: 1px solid #ddd;
  }
  
  .order-products-table td:first-child {
    width: 40%;
  }
  
  .order-products-table td:nth-child(2) {
    width: 10%;
  }
  
  .order-products-table td:nth-child(3) {
    width: 10%;
  }

  .order-products-table td:nth-child(4) {
    width: 10%;
  }
  
  .order-products-table td:nth-child(5) {
    width: 30%;
  }

  
  #histoCommande {
    padding-top: 20px;
    padding-left: 40px;
  }

  /* For smartphone devices */
@media (max-width: 767px) {
  #histoCommande {
    padding-top: 40px;
    margin-top: 20px;
    padding-left: 20px;
  }
}




/* table */

table { font-size: 75%; table-layout: fixed; width: 100%; }
table { border-collapse: separate; border-spacing: 2px; }
th, td { border-width: 1px; padding: 0.5em; position: relative; text-align: left; }
th, td { border-radius: 0.25em; border-style: solid; }
th { background: #EEE; border-color: #BBB; }
td { border-color: #DDD; }


table.meta{ float: right; width: 36%; }
table.meta:after{ clear: both; content: ""; display: table; }

/* table meta */
table.meta th { width: 40%; }
table.meta td { width: 60%; }
