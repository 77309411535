  /* For smartphone devices */
  @media (max-width: 767px) {
    #billingInfo{
        margin-top: 50px;
    }

    #deliveryInfo{
        margin-top: 50px;
    }

    #billingCountryInfo{
        font-size: '8px';
    }

    #deliveryCountryInfo{
        font-size: '8px';
    }

    #paymentDiv{
        padding-left: 1px !important;
        padding-top: 1px;
    }

    #paymentDivContainer{
        margin-left: 1px !important;
    }

    #stripePaymentDiv{
        width: 98% !important;
        margin-left: 1px !important;
        padding: 1px;
        height: 400px !important;
    }

    #cardNumber{
        width: 250px !important;
    }

    #paypalPayment{
        width: 90% !important;
        margin-left: 10px !important;
        margin-top: 70px;
        padding: 30px !important;
    }

    #cancelButton{
        margin-bottom: 40px;
        margin-top: 20px !important;
        margin-left: 20px !important;
    }
  }