/* For smartphone devices */
@media (max-width: 767px) {
    #description{
      margin-top: 40px;
    }

    #includedContainer{
        margin-top: 40px;
    }
  }
  
  /* For devices with width greater than 767px */
  @media (min-width: 768px) {
    /* Fixed height of 720px */

  }