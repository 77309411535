.navbar {
  width: 100%;
  height: 80px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.links {
  display: flex;
  align-items: center;
}

.links a {
  text-decoration: none;
  color: black;
  margin-left: 25px;
  font-size: 21px;
}

.links a:hover {
  color: rgb(102, 116, 77);
}

.link{
  color:black !important;
}

.labelUser{
  margin-left: 35px;
  font-size: 25px;
  text-decoration: none;
  color: white;
}

#cartCount{
  color: red;
  margin-bottom: 40px;
  margin-left: -1px;
  font-size: 12px;
  font-weight: 1000;
}

.labelUser li:hover {
  color: rgb(102, 116, 77);
}


.search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
}

.search-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-input {
  width: 250px;
  padding: 10px;
  border-radius: 5px 0 0 5px;
  border: 1px solid #c0c0c0;
  font-size: 16px;
}

.search-button {
  border-radius: 0 5px 5px 0;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: rgb(102, 116, 77);
  color: white;
}

.search-button:hover {
  background-color: #f0f0f0;
  color: black;
}

#headerLogo h1 {
  color: white;
  font-weight: bolder;
  margin: 0;
}

.navDropdownItem{
  color: black !important;
  margin-left: 0px !important;
  font-size: 15px !important;
  width: auto !important;
}

.navDropdownItem:hover{
  background-color: rgb(102, 116, 77);
  color: white !important;
}

.hamburger-menu {
  display: none; /* Hide the hamburger menu icon by default */
}

#modalCart{
    position: absolute;
    top: 20;
    right: 1%;
    width: auto;
}

/* For smartphone devices */
@media (max-width: 767px) {
  .hamburger-menu {
    display: block; /* Show the hamburger menu icon on small screens */
  }


  .links-menu {
    margin-top: 20px;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
  }
  
  .links-menu a {
    text-decoration: none;
    color: black;
    margin-left: 25px;
    font-size: 18px;
  }
  
  .links-menu a:hover {
    color: rgb(102, 116, 77);
  }


  .links-mobile {
    display: flex;
    margin-top: -7px;
  }
  
  .links-mobile a {
    text-decoration: none;
    color: black;
    margin-left: 25px;
    font-size: 18px;
  }
  
  .links-mobile a:hover {
    color: rgb(102, 116, 77);
  }

  #headerLogo {
    width: 60% !important;
    margin-left: -30px;
  }

  .search-container {
    width: 100% !important;
    margin-left: -30px !important;
  }

  .search-input {
    width: 100% !important;
    padding: 10px;
    border-radius: 5px 0 0 5px;
    border: 1px solid #c0c0c0;
    font-size: 16px;
  }

  #modalCart{
    right: -3%;
    width: 100%;
  }

  #modalCompte{
    width: 350px;
  }

  #navbarContainer {
    display: none; /* Hide the navbar by default on small screens */
    width: 100% !important;
  }

  #headerTop{
    margin-top: 10px;
    color: black !important;
    height: 25px;
  }

  #headerTopItemDiv{
    margin-left: -10px;
  }

  #headerTopItem{
    color: black !important;
    margin-left: 20px !important;
    font-size: 16px !important;
  }

  .mobile-navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    display: none;
    z-index: 99; /* Ensure the modal is above other elements */
  }
  
  .mobile-navbar.open {
    display: block;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    position: absolute;
    top: 6%;
    left: -1%;
    background-color: white;
    height: auto;
    z-index: 99; /* Ensure the modal content is above the overlay */
  } 

  .search-div{
    margin-top: 15px;
    margin-left: 50px;
  }

  .navDropdownItem-mobile{
    margin-left: -10px !important;
  }

  #menu-content {
    color: black;
    font-size: 20px;
  }
}

.hide {
  display: none;
}