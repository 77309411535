.banner {
    position: relative;
    width: 100%;
    height: 450px;
    overflow: hidden;
    margin-bottom: 80px;
  }
  
  .bannerSlide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .bannerSlide.active {
    opacity: 1;
  }
  
  .bannerSlide-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
  }
  