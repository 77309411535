.cart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cartItemParent{
  width: 410px;
  height: auto;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  margin-bottom: 20px;
  background-color: white;
}

.cartItem {
  width: 400px;
  height: auto;
  display: flex;

  align-items: center;
  margin-left: 40px;
}

.cartItem-nom{ 
  margin-left: 40px;
  margin-top: 20px;
}

.cartItem img {
  width: 200px;
}

.cartItem .description {
  width: auto;
  font-size: 17px;
}

.countHandler input {
  width: 40px;
  text-align: center;
  font-weight: bolder;
}

.checkout button {
  width: 150px;
  height: 50px;
  background-color: rgb(19, 19, 19);
  color: white;
  border: none;
  border-radius: 8px;
  margin: 10px;
  cursor: pointer;
}

  /* For smartphone devices */
  @media (max-width: 767px) {
    .cart {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 5px;
    }     

    .cartItemParent{
      width: 360px;
      height: auto;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
      border-radius: 25px;
      margin-bottom: 20px;
      background-color: white;
    }

    .cartItem {
      width: 400px;
      height: auto;
      display: flex;
      align-items: center;
      margin-left: 15px;
    }
    
    .cartItem-nom{ 
      margin-left: 15px;
      margin-top: 20px;
    }
  }